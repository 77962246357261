<template>
  <div class="contact-main">
      <div class="header">
          <h1>Rosman Logistics</h1>
          <p>ייבוא | שיווק | מותגים | טכנולוגיה</p>
      </div>
      <div class="wrapper slide-in-bck-center">
          <div class="field">
              <p>שם מלא</p>
              <el-input v-model="values.full_name"  placeholder="שם מלא" />
          </div>
          <div class="field">
              <p>שם החברה</p>
              <el-input v-model="values.company_name" placeholder="שם החברה" />
          </div>
          <div class="field">
              <p>כתובת אימייל</p>
              <el-input type="email" name="email" v-model="values.email" placeholder="כתובת אימייל" />
          </div>
          <div class="field">
              <p>טלפון נייד</p>
              <el-input type="tel" v-model="values.phone" placeholder="טלפון נייד" />
          </div>
          <div class="field">
            <p>תוכן ההודעה</p>
            <el-input
                class="msg"
                v-model="values.msg"
                :rows="5"
                type="textarea"
                placeholder="תוכן ההודעה..."
            />
          </div>
          <div class="field">
              <el-button @click="handle_submit" style="width:100%;" type="primary">שליחה</el-button>
          </div>
      </div>
       <!-- just for mail -->
    <div class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            נוצר בתאריך
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.createdAt.toLocaleDateString('he') }},{{values.createdAt.toLocaleTimeString('he')}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם מלא
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.full_name }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם החברה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.company_name }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            דוא"ל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.email }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תוכן ההודעה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.msg }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../Methods/Msgs'
import {projectFirestore,projectFunctions,firebase} from '../firebase/config'
export default {
    setup(){
        const error_msg = ref('')
        const values = ref({
            createdAt:new Date(),
            full_name:'',
            company_name:'',
            email:'',
            phone:'',
            msg:'',
            status:'new'
        })

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'full_name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם מלא'
                            return false
                        }
                        break;
                    case 'company_name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם חברה'
                            return false
                        }
                        break;
                    case 'email':
                        if(!value || !validateEmail(value)){
                            error_msg.value = 'עליך להזין כתובת מייל תקינה'
                            return false
                        }
                        break;
                    case 'phone':
                        if(value.length!=10){
                            error_msg.value = 'עליך מספר טלפון תקין'
                            return false
                        }
                        break;    
                    case 'msg':
                        if(!value){
                            error_msg.value = 'עליך תוכן להודעה'
                            return false
                        }
                        break;    
                }
            }

            return true
        }

        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                await increment()
                await store_in_db()
                sendEmail()
                alert('success','הצלחה'
                ,'הטופס נשלח בהצלחה')
                .then(()=>{
                    reset()
                })
                
            }
        }

        const reset=()=>{
            values.value = {
                createdAt:new Date(),
                full_name:'',
                company_name:'',
                email:'',
                phone:'',
                msg:'',
                status:'new'
            }
        }

        const increment = async()=>{
            await projectFirestore.collection('Contact').doc('Contact')
            .set({
                new:firebase.firestore.FieldValue.increment(1),
                total:firebase.firestore.FieldValue.increment(1)
            },{merge:true})
        }

        const store_in_db = async()=>{
            const doc = projectFirestore.collection('Contact').doc('Contact').collection('Forms').doc()
            await doc.set({...values.value,id:doc.id})
        }

        function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        function sendEmail() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["rosman.mate@gmail.com","shiraz@rosman.co.il","yavrosman@gmail.com"],
              from:"מערכת ליצירת קשר",
              title:`נשלח טופס יצירת קשר חדש על ידי ${values.value.full_name}`
          })
        }

        
        return{values,error_msg,handle_submit}
    }
}
</script>

<style scoped>
    .contact-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 25px;
    }
    .header{
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--yellow);
    }
    .wrapper{
        width: 100%;
        max-width: 500px;
        height: 85%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;   
    }
    .field{
        margin-top: 25px;
        width: 100%;
        height: fit-content;
    }
    .msg{
        white-space: pre-wrap;
    }
    .slide-in-bck-center {
	-webkit-animation: slide-in-bck-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bck-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @-webkit-keyframes slide-in-bck-center {
    0% {
        -webkit-transform: translateZ(600px);
                transform: translateZ(600px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-bck-center {
    0% {
        -webkit-transform: translateZ(600px);
                transform: translateZ(600px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        opacity: 1;
    }
    }
    @media screen and (max-width: 600px) {
        .field{
            padding: 0 10px;
            margin-top: 10px;
        }
        .wrapper{
            padding: 10px;
        }
    }


</style>